<!-- Use preprocessors via the lang attribute! e.g. <style lang="scss"> -->
<style>
* {
  margin: 0;
  padding: 0;
}
#vertical-slider-app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 1em;
  margin-top: 1em;
}

.vertical-slider {
  background-color: unset;
  border-radius: 0.25em;
}
.vertical-slider-value {
  text-align: center;
  width: 100%;
  margin-bottom: 0.1em;
  font-family: sans-serif;
}
.vertical-slider-hit-area {
  height: 10em;
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.vertical-slider-container {
  width: 3px;
  height: 100%;
  background-color: #ddd;
  position: relative;
}
.filled {
  background-color: #4CAF50;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.handle {
  width: 1em;
  height: 1em;
  border-radius: 100%;
  background-color: #4CAF50;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50%);
}
.positionBelowA {
  position: absolute !important;
  bottom: -20px;
}
.positionBelowB {
  position: absolute !important;
  bottom: -40px;
}
</style>
<!-- Use preprocessors via the lang attribute! e.g. <template lang="pug"> -->
<template>
  <div id="vertical-slider-app" v-show="sliderShouldShow">
    <div class="vertical-slider">
      <div class="vertical-slider-value">{{ Math.round(getValue) }}</div>
      <div class="vertical-slider-hit-area" @click="onClick">
        <div class="vertical-slider-container" ref="container">
          <div class="filled" :style="{ height: getValue + '%' }"></div>
          <div
            class="handle"
            :style="{ bottom: getValue + '%' }"
            @mousedown="onMouseDown"
          ></div>
        </div>
      </div>
    </div>
    <v-icon :class="interLinked ? '' : 'positionBelowA'" v-show="isAnySliderDirty" @click="saveSliders()">mdi-content-save</v-icon>
    <v-icon :class="interLinked ? '' : 'positionBelowB'" v-show="isAnySliderDirty" @click="resetToOriginal()">mdi-refresh</v-icon>
  </div>
</template>

<script>
function clamp(val, min, max) {
  return Math.max(min, Math.min(max, val));
}
export default {
  data() {
    return {
      dragging: false,
      originalValues: null
    };
  },
  props: {
    campaign: {
      type: Object,
      required: true
    },
    sliderGroups: {
      type: Array,
      required: true
    },
    interLinked: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getValue()
    {
      let slider = this.sliders.find((slider) => slider.campaignId === this.campaign.id);
      if(slider)
      {
        return Number(slider.value)
      }
      else
        return 0
    },
    sliders() {
      if(this.sliderObj === undefined)
        return [];
      return this.sliderObj.sliders;
    },
    sliderObj() {
      return this.sliderGroups.find((group) => group.groupId === this.campaign.groupId);
    },
    // Function to check if the slider should show
    sliderShouldShow() {
      let slider = this.sliders.find((slider) => slider.campaignId === this.campaign.id);
      return slider !== undefined;
    },
    isAnySliderDirty() {
      let dirty = false;
      if(!this.interLinked)
      {
        let slider = this.sliders.find((slider) => slider.campaignId === this.campaign.id);
        if(slider && slider.isDirty === true)
          return true;
      }
      else
      {
        this.sliders.forEach((slider) => {
          if (slider.isDirty) {
            dirty = true;
          }
        });
      }
      return dirty;
    },
  },
  watch: {
    sliderGroups: {
        handler: function (newVal, oldVal) {
          //this.sliderShouldShow()
        },
        deep: true,
    }
  },
  mounted() {
    document.addEventListener("mousemove", (e) => {
      if (!this.dragging) return;
      this.update(e);
    });
    document.addEventListener("mouseup", (e) => {
      this.dragging = false;
    });
    // create a clone of sliderGroups
    // clone not using parse, stringify 
  },
  methods: {
    saveSliders() {
      // save sliders
      this.originalValues = JSON.parse(JSON.stringify(this.sliderGroups));
      // if it's not interlinked, find the campaignId slider and set it to shouldSave
      let slider = this.sliders.find((slider) => slider.campaignId === this.campaign.id);
      if(slider && this.interLinked === false)
        slider.shouldSave = true;

      this.sliderObj.shouldSave = true;
      this.resetDirty();
    },
    resetToOriginal() {
      // as it's a prop, loop over the each param of sliderGroups and reset it to original value Object.
      // ultimately set sliderObj
      this.sliderGroups.forEach((sliderGroup) => {
        sliderGroup.sliders.forEach((slider) => {
          let originalValue = this.originalValues.find((originalValue) => originalValue.groupId === sliderGroup.groupId);
          let shouldReset = this.interLinked || slider.campaignId === this.campaign.id;
          if (originalValue && shouldReset) {
            let originalSlider = originalValue.sliders.find((originalSlider) => originalSlider.campaignId === slider.campaignId);
            if (originalSlider) {
              slider.value = originalSlider.value;
            }
          }
        });
      });
      
      this.resetDirty();
    },
    resetDirty() {
      // set all sliders to not dirty
      this.sliders.forEach((slider) => {
        // if !interLinked, only reset the campaignId slider
        if (!this.interLinked)
        {
          if(slider.campaignId === this.campaign.id)
            slider.isDirty = false;
        }
        else
          slider.isDirty = false;
      });
    },

    adjustSliders(recalcing) {
      if(this.interLinked === false)
        return;
      let total = 100;
      let siblingsTotal = this.getSiblingsTotal();
      let grandTotal = this.getGrandTotal();
      let diff = grandTotal - total;
      // adjust all other sliders proportionally to the change in this slider, ensure total is 100 and value can start at 0
      for(var s = 0; s < this.sliders.length; s++)
      {
        let slider = this.sliders[s];
        if (slider.campaignId !== this.campaign.id) {
          // check for divide by zero
          if (siblingsTotal === 0) {
            slider.value = recalcing ? 0 : 1;
            recalcing = false;
          } else {
            slider.value = Math.round(clamp(slider.value - (diff * (slider.value / siblingsTotal)), 0, 100));
            }
        }
        // ensure total is 100
        grandTotal = this.getGrandTotal();
        if (grandTotal === total) {
          return;
        }
      }
      
      if (total !== grandTotal && !recalcing) {
        this.adjustSliders(true);
      }
    },
    onMouseDown() {
      this.dragging = true;
    },
    getRect() {
      const container = this.$refs.container;
      return container.getBoundingClientRect();
    },
    update(e) {
      const rect = this.getRect();
      let val = 100 - ((clamp(e.clientY, rect.top, rect.bottom) - rect.top) / rect.height) * 100;
      this.updateValue(val)
    },
    onClick(e) {
      this.update(e);
      this.$nextTick();
    },
    updateValue(value) {
      if(this.originalValues === null)
        this.originalValues = JSON.parse(JSON.stringify(this.sliderGroups));

      // get value from slider
      let slider = this.sliders.find((slider) => slider.campaignId === this.campaign.id);
      if(slider)
      {
        value = Math.round(value);
        this.adjustSliders();
        slider.value = value
        this.sliderObj.isDirty = true;
        slider.isDirty = true;
      }
    },
    getSiblingsTotal() {
      let total = 0;
      this.sliders.forEach((slider) => {
        if (slider.campaignId !== this.campaign.id) {
          total += slider.value;
        }
      });
      return total;
    },
    getGrandTotal() {
      let total = 0;
      this.sliders.forEach((slider) => {
        total += Math.round(slider.value);
      });
      return total;
    }
  }
};
</script>
