<template>
  <v-container v-if="locationtableData && previewLocation.name">
    <v-row>
      <v-col
        class="mt-3"
        cols="3"
        v-if="screenLocationsEdit"
      >
        <v-row>
          <v-col
            cols="12"
            class="pa-0 ma-0"
          >
         
            <div
              @dragover.prevent
              @drop.prevent
            >
              <input
                ref="locationsUpload"
                type="file"
                multiple
                hidden
                @change="uploadFile"
              >
              
              <div
                ref="dragDiv"
             
                @drop="dragFile"
                @dragleave="$refs.dragDiv.style.backgroundColor = 'transparent'"
                @dragover="$refs.dragDiv.style.backgroundColor = 'grey'"

              >         
              <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-hover v-slot="{ hover }">
                        <div
                          ref="dragDiv"
                          v-bind="attrs"
                          v-on="on"
                          @drop="dragFile"
                          @dragleave="$refs.dragDiv.style.backgroundColor = 'transparent'"
                          @dragover="$refs.dragDiv.style.backgroundColor = 'grey'"
                          style="
                            background-color: transparent;
                            padding: 0.66em 0em;
                            transition: 0.1s;
                          "
                          :style="hover ? {border: 'solid 1px rgba(255,255,255, .8)'} : {border: 'solid 1px rgba(255,255,255, .3)'}"
                          class="rounded"
                        >
                          <div style="width:100%; text-align:center;">
                            <v-icon large>
                              mdi-cloud-upload
                            </v-icon>
                          </div>
                          <div style="width:100%; text-align:center;">
                  Drag & Drop To Upload Locations
                  <br/>
                  Or
                  <br/>
                  <v-btn
                    color="primary"
                    small
                    @click="$refs.locationsUpload.click()"
                  >
                    Browse Files
                  </v-btn>
                </div>
                        </div>
                      </v-hover>
                    </template>
                    <span>Upload Creatives</span>
                  </v-tooltip>
              <!-- <v-tooltip top>
      <template v-slot:activator="{ on }"> 
        <div style="width:100%; text-align:center;">
          <v-icon large v-on="on">
            mdi-cloud-upload
          </v-icon>
        </div>
      </template>
      <span>Drag & Drop To Upload Locations</span>
    </v-tooltip> -->
   <!-- <div style="width:100%; text-align:center;">
                  Drag & Drop To Upload Locations
                  <br/>
                  Or
                  <br/>
                  <v-btn
                    color="primary"
                    small
                    @click="$refs.locationsUpload.click()"
                  >
                    Browse Files
                  </v-btn>
                </div> -->
                <br/>
                <div v-if="uploadedLocations.length">
                  <ul
                    v-for="(uploadedLocation, index) in uploadedLocations"
                    :key="index"
                  >
                    <li>{{ uploadedLocation.name }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="pa-0 ma-0"
          >
            <v-btn
              v-if="uploadedLocations.length > 0"
              color="primary"
              block
              small
              @click="uploadLocationsToAPI"
            >
              Save
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="isDrive">
          <v-col cols="12" class="pa-0 ma-0">
            <v-select

              v-model="type"
              :items="availableTypes"
              label="Type"
              outlined
              class="mt-4"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col :cols="screenLocationsEdit ? 9 : 12">
        <v-row>
          <v-col cols="12">
            <div v-if="previewLocation" style="width: 100%; height: 500px">
              <l-map
                ref="mapElement"
              >
                <l-tile-layer
                  :url="url"
                  :attribution="attribution"
                />

                  <l-marker
                    v-for="(locationtableData, index) in mapLocationTableData"
                    :key="index"
                    :lat-lng="[
                      getLatDrive(locationtableData), 
                      getLongDrive(locationtableData)
                    ]"
                    :z-index-offset="getPinZindex(locationtableData.lastPingSeconds)"
                    @click="selectLocation(locationtableData)"
                    :icon="mapMarkerIcon(locationtableData, locationtableData.lastPingSeconds)"
                  >
                    <l-popup
                      :lat-lng="[
                        getLatDrive(locationtableData), 
                        getLongDrive(locationtableData)
                      ]"
                    >
                      {{ (locationtableData.idA?locationtableData.idA+'':locationtableData.idE+'') }} <br/> {{ locationtableData.name }} <br/> {{locationtableData.postCode}}
                    </l-popup>
                  </l-marker>
              </l-map>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <!-- Download Location List -->
      <v-col
          v-if="locationListMedia"
          xl="1"
          lg="1"
          style="min-width: 60px; max-width: 60px;"
          cols="2"
      >
        <div
          @click.prevent="downloadItem({media: locationListMedia})"
          class="mx-1"
          style="cursor: pointer"
        >
          <v-icon color="green" large>
            mdi-microsoft-excel
          </v-icon>
          <span class="centerIt" style="color: grey; font-size: 10px; margin-bottom: 4px;">
            Download
          </span>
        </div>
      </v-col>
      <v-col cols="10">
        <b>File in use:</b> {{listFileName}}
        <br/>
        <b>Location List Date:</b> {{listDateTime}}
      </v-col>
    </v-row>
    <v-card class="pa-0 ma-0">
      <v-col cols="12">
        <v-text-field
          v-model="searchLocation"
          class="mt-4"
          label="Search Locations"
          outlined
          append-icon="mdi-magnify"
          clearable
          @click:clear="clearSearch"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        class="pa-0 ma-0"
      >
        <v-data-table
          :headers="locationTableHeaders"
          :items="filteredLocationTableData"
          @click:row="selectLocation"
        >
          <template
            v-slot:item.name="{ item }"
          >
            <v-tooltip
              content-class="top"
              top
            >
              <template v-slot:activator="{ attrs, on }">
                <span 
                  v-bind="attrs"
                  v-on="on" 
                  class="mouse-pointer"
                >{{item.name}}</span>
              </template>
              <span>Set Active Location</span>
            </v-tooltip>
          </template>
          <template
            v-slot:item.lastPingSeconds="{ item }"
          >
            <span
              :style="getDotStyle(item.lastPingSeconds)"
              class="dot"
            ></span>
          </template>
        </v-data-table>
      </v-col>
    </v-card>
  </v-container>
</template>
<script>
  import CampaignController from '@/services/controllers/Campaign'
  import L, { latLng } from 'leaflet'
  import { LMap, LTileLayer, LMarker, LPopup, LIcon } from 'vue2-leaflet'
  import vue2Dropzone from 'vue2-dropzone'
  import 'vue2-dropzone/dist/vue2Dropzone.min.css'
  import collectionSearch from '@/mixins/collection/search'
  import axios from 'axios'

  export default {
    props: ['selectedBurst', 'selectedScreen', 'selectedCampaignBurstScreen', 'previewLocation'],
    
    components: {
      latLng,
      L,
      LIcon,
      LMap,
      LPopup,
      LTileLayer,
      LMarker,
      vueDropzone: vue2Dropzone,
    },

    data: () => ({
      locationListMedia: null,
      selectedCampaignId: null,
      zoomLevel:10,
      campaignBurstScreens: null,
      searchLocation: null,
      url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
      marker: L.latLng(47.41322, -1.219482),
      uploadedLocations: [],
      // Add the drag and drop status as an object
      status: {
        over: false,
        dropped: false,
      },
      listFileName: "",
      listDateTime: "",
      locationTableHeaders: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Postcode',
          align: 'start',
          sortable: true,
          value: 'postCode',
        },
        {
          text: 'latitude',
          align: 'start',
          sortable: true,
          value: 'latitude',
        },
        {
          text: 'longitude',
          align: 'start',
          sortable: true,
          value: 'longitude',
        },
        {
          text: 'idA',
          align: 'start',
          sortable: true,
          value: 'idA',
        },
        {
          text: 'idB',
          align: 'start',
          sortable: true,
          value: 'idB',
        },
        {
          text: 'idC',
          align: 'start',
          sortable: true,
          value: 'idC',
        },
        {
          text: 'idD',
          align: 'start',
          sortable: true,
          value: 'idD',
        },
        {
          text: 'Activity',
          align: 'start',
          sortable: true,
          value: 'lastPingSeconds',
        },
      ],
      locationtableData: [],
      mapLocationTableData: [],
      availableTypes : ['Drive', 'Locations'],
      type: 'Locations', // or 'drive'
      isDrive: false

    }),
    
    watch: {
      selectedCampaignBurstScreen: function (newVal) {
        this.getLocationList()
        this.mapLocationTableData = []
      }
    },

    mounted() {
      this.mapLoadedNextTick();
    },

    async created() {
      this.selectedCampaignId = parseInt(this.$route.query.cid)
      await this.getLocationList()
    },

    computed: {
      filteredLocationTableData() {
        /*
        // Opted to -1 from from frame count as requested. We need the Default for Dynamic
        // Remove fst entry if its default from location table
        if(this.locationtableData.length > 0 && this.locationtableData[0].name.includes("DEFAULT")) {
          this.locationtableData.shift()
        }
        */
        if(this.searchLocation && this.searchLocation.length > 0) {
          return collectionSearch(this.locationtableData, 'all', this.searchLocation, 0)
        }
        return this.locationtableData
      },
      getLatDrive() {
        return (lat) => this.getCoordinate(lat, 'lat', true)
      },
      getLongDrive() {
        return (long) => this.getCoordinate(long, 'long', true)
      },

      screenLocationsEdit() {
        return this.$store.state.Permissions.screenLocationsEdit
      },
    },
    
    methods: {
      async downloadItem({ media }) {
        let downloadUrl = media.url
        let downloadFileName = media.originalFileName
        let mimeType = media.mimeType

        let cachebuster = Math.round(new Date().getTime() / 1000)

        const response = await axios.get(downloadUrl +"?rn="+cachebuster, { responseType: "blob" })
        const blob = new Blob([response.data], { type: mimeType })
        const link = document.createElement("a")

        link.href = URL.createObjectURL(blob)
        link.download = downloadFileName
        link.click()

        URL.revokeObjectURL(link.href)
      },

      async getLocationListMedia() {
        // Get Location List Media
        const { data = false } = await CampaignController.getLocationListMedia(this.selectedCampaignId, this.selectedBurst.id, this.selectedScreen.id)
        .catch((err) => {
          // Show error toast
          this.errorMessage = ''+ err.response.data.message
          this.$root.$emit('snackbarError', this.errorMessage)
          return false
        })
        // If data is false then return
        if (!data) return
        // Format response data
        this.locationListMedia = data
      },

       // Map Marker Color
       mapMarkerColor (locationData, sec){
        const iconColor = locationData.type ? '49A0CD' : this.getPin2(sec);
        const svgString = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path fill="#`+iconColor+`" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"/></svg>`;
        return svgString;
      },
      // Map Marker Icon
      mapMarkerIcon(locationData, sec) {
        return L.icon({
          iconUrl: `data:image/svg+xml;base64,${btoa(this.mapMarkerColor(locationData, sec))}`,
          iconSize: [35, 35],
          iconAnchor: [16, 16],
          popupAnchor: [1, -15]
        });
      },
      // Get Coordinate
      getCoordinate(coordinate, coordinateType, isAltLatLong) {
        if (coordinate && undefined != coordinate) {
          if (this.type.includes('Drive') && isAltLatLong && coordinate.altLatLong != undefined) {
            const [latValue, longValue] = coordinate.altLatLong.split(',');
            return coordinateType === 'lat' ? parseFloat(latValue) : parseFloat(longValue)
          } else  return coordinateType === 'lat' ? parseFloat(coordinate.latitude) : parseFloat(coordinate.longitude)
        } else return 0
      },
      // Map Loaded Next Tick
      mapLoadedNextTick() {
        if(this.$refs.mapElement) this.$refs.mapElement.mapObject.flyTo(this.getPinLatLong(this.previewLocation), this.zoomLevel) // Set preview location
        else setTimeout(this.mapLoadedNextTick,100); // check again in a second
      },
      // Get Pin Lat Long
      getPinLatLong(pin) {
        if(this.type == 'Drive'){
          if(pin == null || pin.altLatLong == null) return [51.5, -0.1]
          return (pin == null || pin.altLatLong == null ? [51.5, -0.1] : [parseFloat(pin.altLatLong.split(',')[0]), parseFloat(pin.altLatLong.split(',')[1])]);
        } else if(this.type == 'Locations') return (pin == null || (pin.latitude == -1 && pin.longitude == -1) ? [51.5, -0.1] : [pin.latitude, pin.longitude]);
      },
      // Select Location
      selectLocation (pin) {
        this.zoomLevel = 18
        this.$emit('setPreviewLocation', { newLocation: pin })
        this.$refs.mapElement.mapObject.flyTo(this.getPinLatLong(pin), this.zoomLevel)
      },
      // Get Dot Style
      getDotStyle(secs) {
        switch (true) {
          case secs === -1:
            return 'background:gray';
          case secs !== -1 && secs / 60 <= 10:
            return 'background:#6AA842;';
          case secs !== -1 && secs / 60 >= 10 && secs / 60 <= 20:
            return 'background:#9BAB37;';
          case secs !== -1 && secs / 60 >= 20 && secs / 60 <= 30:
            return 'background:#DC911B;';
          case secs !== -1 && secs / 60 >= 30 && secs / 60 <= 40:
            return 'background:#C66B25;';
          case secs !== -1 && secs / 60 >= 40 && secs / 60 <= 50:
            return 'background:#b24527;';
          case secs !== -1 && secs / 60 >= 50 && secs / 60 <= 60:
          case secs !== -1 && (secs / 60) > 60:
            return 'background:#A21C26;';
          default:
            return 'blue';
        }
      },
      // Get Dot Style
      getPin2(secs) {
        switch (true) {
          case secs === -1:
            return '808080';
          case secs !== -1 && secs / 60 <= 10:
            return '6AA842';
          case secs !== -1 && secs / 60 >= 10 && secs / 60 <= 20:
            return '9BAB37';
          case secs !== -1 && secs / 60 >= 20 && secs / 60 <= 30:
            return 'DC911B';
          case secs !== -1 && secs / 60 >= 30 && secs / 60 <= 40:
            return 'C66B25';
          case secs !== -1 && secs / 60 >= 40 && secs / 60 <= 50:
            return 'b24527';
          case secs !== -1 && secs / 60 >= 50 && secs / 60 <= 60:
          case secs !== -1 && (secs / 60) > 60:
            return 'A21C26';
          default:
            return '';
        }
      },
      /* Get Pin
      getPin(secs) {
        switch (true) {
          case secs === -1:
            return 'pins/gray.png';
          case secs !== -1 && secs / 60 <= 10:
            return 'pins/1.png';
          case secs !== -1 && secs / 60 >= 10 && secs / 60 <= 20:
            return 'pins/2.png';
          case secs !== -1 && secs / 60 >= 20 && secs / 60 <= 30:
            return 'pins/3.png';
          case secs !== -1 && secs / 60 >= 30 && secs / 60 <= 40:
            return 'pins/4.png';
          case secs !== -1 && secs / 60 >= 40 && secs / 60 <= 50:
            return 'pins/5.png';
          case secs !== -1 && secs / 60 >= 50 && secs / 60 <= 60:
          case secs !== -1 && (secs / 60) > 60:
            return 'pins/6.png';
          default:
            return '';
        }
      }, */
      // Get Pin Zindex
      getPinZindex (secs) {
        if(secs === -1) return 1;
        let out = (90000 - secs);
        return out;
      },
      // Clear Search
      clearSearch () {
        this.searchLocation = null
        this.getLocationList()
      },
      // Get Location List
      async getLocationList () {
        CampaignController.getLocationList(this.selectedCampaignBurstScreen.id)
          .then((res) => {
            this.locationtableData = res.data.locationList
            // Delte first entry of this.locationtableData
            //this.locationtableData.shift() 
            this.listFileName = res.data.fileName // set file name
            this.listDateTime = res.data.dateTime // set date time
            // headers
            const headers = res.data.headers;
            this.locationTableHeaders = [] // reset headers
            // populate headers
            for(var i = 0; i < headers.length; i++)
            {
              let header = {
                text: headers[i].header,
                align: 'start',
                sortable: true,
                value: headers[i].columnName,
              }
              this.locationTableHeaders.push(header)
            }
            // populate location table data
            this.locationtableData = this.$parent.$parent.$parent.expandLocationExtraInfo(this.locationtableData)
            this.$parent.$parent.$parent.setAllLocations(this.locationtableData) // populate global locations

            this.locationtableData.filter(x => x.idA != 'DEFAULT').forEach((location) => {
              this.mapLocationTableData.push(JSON.parse(JSON.stringify(location)))
            })

            for (var i = 0; i < this.mapLocationTableData.length; i++) {
              if (this.mapLocationTableData[i].altLatLong != undefined) {
                this.mapLocationTableData[i].latitude = parseFloat(this.mapLocationTableData[i].altLatLong.split(',')[0]);
                this.mapLocationTableData[i].longitude = parseFloat(this.mapLocationTableData[i].altLatLong.split(',')[1]);
                this.mapLocationTableData[i].type = 'Drive';
              }
            }

            // Check if at least one property altLatLong exists
            this.isDrive = this.mapLocationTableData.some((location) => {
              return location.altLatLong != undefined
            })

            this.mapLocationTableData = this.locationtableData.concat(this.mapLocationTableData);
            // Remove every object in mapLocationTableData that has the name 'DEFAULT'
            this.mapLocationTableData = this.mapLocationTableData.filter((location) => {
              return !location.name.includes("DEFAULT")
            })
            this.mapLocationTableData = this.$parent.$parent.$parent.expandLocationExtraInfo(this.mapLocationTableData)
            this.$parent.$parent.$parent.setAllLocations(this.locationtableData) // populate global locations
            // Set preview location
            if(!this.previewLocation.name) { 
              this.$emit('setPreviewLocation', { newLocation: this.locationtableData[0] })
            }
          })
          .catch((err) => {
            this.$root.$emit('snackbarError', ''+ err.response.data.message)
          })

          // get the media associated with the list
          await this.getLocationListMedia()
      },
      // Upload Locations To API
      uploadLocationsToAPI () {
        const formData = new FormData()

        formData.append('file', this.uploadedLocations[0])
        formData.append('campaignId', this.selectedCampaignId)
        formData.append('campaignBurstId', this.selectedBurst.id)
        formData.append('screenId', this.selectedScreen.id)

        CampaignController.uploadLocationList(formData)
          .then((res) => {
            this.$root.$emit(
              'snackbarSuccess',
              'Successfully uploaded sitelist.',
            )
            // Refresh Locations
            this.getLocationList()
          })
          .catch((err) => {
            this.$root.$emit('snackbarError', ''+ err.response.data.message)
          })
      },

      uploadFile (e) {
        this.uploadedLocations = Object.values(e.target.files)
      },

      dragFile (e) {
        this.uploadedLocations = Object.values(e.dataTransfer.files)
        this.$refs.dragDiv.style.backgroundColor = 'transparent'
      },

    },
  }
</script>

<style scoped>
  .dot {
    height: 15px;
    width: 15px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }
</style>
